import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import blogs from "./../../../../data/blogs.json";
const latestBlogs = blogs.filter((blog) => blog.type === "Latest");


const LatestStories = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={"h6"} gutterBottom>
            Latest stories
          </Typography>
          <Typography color={"text.secondary"}>
            Here’s what we’ve been up to recently.
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {latestBlogs.map((item, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Box
              component={"a"}
              href={`/blog-article/${item.slug}`}
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box component={Card} width={1} height={1}>
                <CardMedia
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 300, md: 360 },
                    position: "relative",
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.7)"
                        : "none",
                  }}
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    flexWrap={"wrap"}
                  >
                    {item.tags.map((item) => (
                      <Chip
                        key={item}
                        label={item}
                        size={"small"}
                        color={"primary"}
                        sx={{ marginBottom: 1, marginRight: 1 }}
                      />
                    ))}
                  </Box>
                  <Typography
                    variant={"h6"}
                    fontWeight={700}
                    align={"center"}
                    sx={{ textTransform: "uppercase" }}
                  >
                    {item.title}
                  </Typography>
                  <Box marginY={1}>
                    <Typography
                      variant={"caption"}
                      align={"center"}
                      color={"text.secondary"}
                      component={"i"}
                    >
                      {item.author} - {item.date}
                    </Typography>
                  </Box>
                  <Typography color="text.secondary" align={"center"}>
                    {item.description}
                  </Typography>
                </CardContent>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LatestStories;
