import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  LatestStories,
  PopularNews,
} from './components';

import {
  BigTextHeader,
  CurrentPageNavText
} from 'customComponents';

const BlogNewsroom = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main colorInvert={true}>
      <BigTextHeader
        headerText={'Blogs'}
        subheaderText={''}
        showButton={false}
      />
      <CurrentPageNavText navLocText={'Home > Blogs'} />
      <Container>
        <PopularNews />
      </Container>
      <Container>
        <Grid item xs={12} md={8}>
          <LatestStories />
        </Grid>
      </Container>
    </Main>
  );
};

export default BlogNewsroom;
